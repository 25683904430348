import React from 'react'

function Hamburger() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 12h18M3 6h18M3 18h18"
      ></path>
    </svg>
  )
}

export default Hamburger
