import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby'
import React, { FC, ReactNode } from 'react'

type CustomGatsbyLink = Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'>

type Props = {
  children: JSX.Element | JSX.Element[] | ReactNode
  to: string
  disabled?: boolean
} & CustomGatsbyLink

// https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#reminder-use-link-only-for-internal-links
const internalRegExp = new RegExp(/^\/(?!\/)/)

const CustomLink: FC<Props> = ({ children, to, disabled, ...other }) => {
  if (disabled) return <>{children}</>

  const isInternalLink = internalRegExp.test(to)

  if (isInternalLink) {
    return (
      <GatsbyLink to={to} {...other}>
        {children}
      </GatsbyLink>
    )
  }

  const defaultAriaLabel = `${isInternalLink ? 'Internal' : 'External'} link`

  return (
    <a href={to} aria-label={defaultAriaLabel} {...other}>
      {children}
    </a>
  )
}

export default CustomLink
