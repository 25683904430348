import axios, { AxiosRequestConfig } from 'axios'
import { navigate } from 'gatsby'
import { Category, ImageSources } from '../types'

const encodeDataToFormData = data => {
  const formData = new FormData()
  Object.keys(data).map(key => {
    if (key === 'files') {
      for (const file of data[key]) {
        formData.append(key, file, file.name)
      }
    } else {
      formData.append(key, data[key])
    }
  })

  return formData
}

export function submitNetlifyFileForm(data: any) {
  const opts: AxiosRequestConfig = {
    url: '/',
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: encodeDataToFormData(data),
  }

  return axios(opts)
}

export function submitNetlifyForm(form: HTMLFormElement) {
  const formData = new FormData(form)
  const body = new URLSearchParams(formData as any).toString()

  const opts: AxiosRequestConfig = {
    url: '/',
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: body,
  }

  return axios(opts)
}

export function parseContentfulFileUrl(str: string) {
  return 'https:' + str
}
export const disablePageScroll = () => {
  const html = document.querySelector('html')
  if (!html) return
  html.style.overflow = 'hidden'
}

export const enablePageScroll = () => {
  const html = document.querySelector('html')
  if (!html) return
  html.style.overflow = 'auto'
}

export const getImageUrl = (sources: ImageSources) => {
  const url = sources.srcWebp ?? sources.src
  return parseContentfulFileUrl(url)
}

export const translateCategory = (category: Category) => {
  switch (category) {
    case 'ai':
      return 'AI Services'
    case 'software_development':
      return 'Software Development'
    case 'ui_design':
      return 'Design and Branding'
    case 'product_design':
      return 'Product Design'
    default:
      return category
  }
}

export const mapCategoryToTags = (category: Category): string[] => {
  switch (category) {
    case 'ai':
      return ['ai', 'development']
    case 'software_development':
      return ['development', 'iot', 'ai']
    case 'ui_design':
      return ['design', 'branding']
    case 'product_design':
      return ['design', 'cto', 'innovation', 'service design']
    default:
      return []
  }
}

export const navigateToWorkWithCategoryFilters = (categories: Category | Category[]) => {
  navigate(`/work?category_filters=${categories.toString()}`)
}
