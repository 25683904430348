import styled, { css } from 'styled-components'
import { bodyFontBold } from '../../theme/typography'
import { ButtonVariant } from './Button'

const defaultContainer = css`
  background: var(--accent);
`
const backgroundContainer = css`
  background: var(--background);
`
const outlineContainer = css`
  background: var(--accent);
  border: 2px solid var(--background);
`
const outlineBackgroundContainer = css`
  background: var(--background);
  border: 2px solid var(--accent);
`

const inactiveContainer = css`
  background-color: var(--foreground-variant);
`

export const Text = styled.h3<{
  size?: 'default' | 'small'
}>`
  font-size: ${({ size }) => (size === 'default' ? '2rem' : '1.8rem')};
  color: white;
  margin-top: 2px;
  ${bodyFontBold}
`

export const Container = styled.button<{
  variant: ButtonVariant
  disabled?: boolean
  size?: 'default' | 'small'
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ size }) => (size === 'default' ? '62px' : '50px')};
  border-radius: ${({ size }) => (size === 'default' ? '4px' : '4px')};
  border-radius: 12px;
  padding: 0 20px;
  min-width: ${({ size }) => (size === 'default' ? '200px' : '160px')};
  max-width: 100%;
  background: var(--accent);
  cursor: pointer;
  transition: 0.1s;
  position: relative;

  &:hover {
    background: var(--accent-light);
  }

  ${({ disabled }) => disabled && 'cursor: not-allowed'};
  ${({ variant }) => variant === 'default' && defaultContainer}
  ${({ variant }) => variant === 'background' && backgroundContainer}
  ${({ variant }) => variant === 'outline' && outlineContainer}
  ${({ variant }) => variant === 'outline-background' && outlineBackgroundContainer}
  ${({ variant }) => variant === 'secondary' && inactiveContainer}
`
