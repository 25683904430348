import { useLocation } from '@reach/router'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import ChatBubble from '../../assets/svg/ChatBubble'
import Cross from '../../assets/svg/Cross'
import Hamburger from '../../assets/svg/Hamburger'
import LogoSmall from '../../assets/svg/LogoSmall'
import { Spacer } from '../../theme/base'
import { mediaQueries } from '../../theme/mediaBreakpoints'
import { Body2, Title1, Title2 } from '../../theme/typography'
import { disablePageScroll, enablePageScroll } from '../../util/helpers'
import Button from '../Button'
import CustomLink from '../__general/CustomLink'
import './header.css'
import * as S from './styled'

type Props = {
  mode?: 'light' | 'dark'
  transparent?: boolean
}

export type AnimationState = 'hidden' | 'animate-in' | 'animate-out'

export const routes = [
  {
    title: 'AI',
    route: '/ai',
  },
  {
    title: 'Services',
    route: '/services',
  },
  {
    title: 'Work',
    route: '/work',
  },
  {
    title: 'Career',
    route: 'https://jobs.redmind.se/',
  },
  {
    title: 'Talent Accelerator',
    route: 'https://talentaccelerator.redmind.se/',
  },
]

const Header: FunctionComponent<Props> = ({ mode = 'dark', transparent }) => {
  const [showDrawer, setShowDrawer] = useState(false)
  const [animationState, setAnimationState] = useState('hidden')
  const [isScrolling, setIsScrolling] = useState(false)
  const location = useLocation()
  const isMobile = useMediaQuery({
    query: mediaQueries.tablet,
  })

  useEffect(() => {
    const onScroll = () => {
      if (!isScrolling && window.scrollY > 0) {
        setIsScrolling(true)
      }

      if (window.scrollY <= 0) {
        setIsScrolling(false)
      }
    }

    document.addEventListener('scroll', onScroll)

    return () => document.removeEventListener('scroll', onScroll)
  }, [])

  useEffect(() => {
    if (showDrawer) {
      disablePageScroll()
    } else {
      enablePageScroll()
    }

    return enablePageScroll
  }, [showDrawer])

  const toggleDrawer = () => {
    const newState = !showDrawer
    setAnimationState(newState ? 'animate-in' : 'animate-out')

    setTimeout(
      () => {
        console.log('attempting to set show drawer')
        setShowDrawer(newState)
      },
      newState ? 0 : 1100,
    )
  }

  const MenuTypography = isMobile ? Title2 : Title1

  const mobileDrawer = (
    <S.Overlay className={`${animationState}-overlay`}>
      <S.DrawerBackdrop className={`${animationState}-drawer`}>
        <span className={`${animationState}-content`}>
          <S.DrawerMenu>
            <div>
              <span onClick={toggleDrawer}>
                {routes.map(({ route, title }) => (
                  <CustomLink to={route} key={route}>
                    <MenuTypography uppercase clickable>
                      {title}
                    </MenuTypography>
                  </CustomLink>
                ))}
                <CustomLink to="/contact">
                  <MenuTypography uppercase clickable>
                    Let's Talk
                  </MenuTypography>
                </CustomLink>
              </span>
            </div>
          </S.DrawerMenu>
        </span>
      </S.DrawerBackdrop>
    </S.Overlay>
  )

  return (
    <>
      {showDrawer && mobileDrawer}
      <S.Container showBackground={isScrolling} transparent={transparent}>
        <S.LogoContainer>
          <CustomLink to="/" title="Home">
            <LogoSmall />
          </CustomLink>
          <Spacer w08 />
        </S.LogoContainer>
        {isMobile && (
          <div
            onClick={toggleDrawer}
            style={{ padding: '1rem', marginTop: '2px', marginLeft: '1rem' }}
          >
            {showDrawer ? <Cross /> : <Hamburger />}
          </div>
        )}
        {!isMobile && (
          <S.NavContainer>
            {routes.map(({ title, route }) => {
              const active = location.pathname.includes(route)

              return (
                <CustomLink to={route} key={route}>
                  <Body2 white={mode === 'light'} accent={active}>
                    {title}
                  </Body2>
                </CustomLink>
              )
            })}
            <CustomLink to="/contact">
              <Button size="small" title="Let's Talk" Icon={ChatBubble} />
            </CustomLink>
          </S.NavContainer>
        )}
      </S.Container>
    </>
  )
}

export default Header
