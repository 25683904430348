import React, { FunctionComponent } from 'react'
import * as S from './styled'

export type ButtonVariant =
  | 'background'
  | 'outline'
  | 'outline-background'
  | 'default'
  | 'secondary'

type Props = {
  title: string
  onClick?: () => void
  variant?: ButtonVariant
  disabled?: boolean
  Icon?: any
  size?: 'default' | 'small'
} & React.HTMLAttributes<HTMLButtonElement>

const Button: FunctionComponent<Props> = ({
  title,
  variant = 'default',
  disabled,
  Icon,
  size = 'default',
  ...buttonProps
}) => {
  return (
    <S.Container variant={variant} disabled={disabled} {...buttonProps} size={size}>
      <S.Text size={size}>{title}</S.Text>
    </S.Container>
  )
}

export default Button
