import styled, { css } from 'styled-components'
import { breakpoints } from '../../theme/mediaBreakpoints'

type ContainerProps = { rounded: boolean }

export const roundedImageCss = css`
  &,
  img {
    border-radius: 12px;
    overflow: hidden;

    ${breakpoints.mobile} {
      border-radius: 8px;
    }
  }
`

export const Container = styled.div<ContainerProps>`
  ${({ rounded }) => rounded && roundedImageCss}
`

type ImageProps = {
  blur: boolean
}

export const Image = styled.img<ImageProps>`
  transition: filter 150ms;
  filter: blur(${({ blur }) => (blur ? 25 : 0)}px);
  clip-path: inset(0);
`

export const BackgroundImage = styled.div<ImageProps & { src: string }>`
  ${roundedImageCss}
  background-image: url("${({ src }) => src}");
  transition: filter 150ms;
  filter: blur(${({ blur }) => (blur ? 25 : 0)}px);
  clip-path: inset(0);
`
