import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { breakpoints } from './mediaBreakpoints'

export type ThemeColorProps = {
  primary?: boolean | string
  primaryLight?: boolean | string
  secondary?: boolean | string
  accent?: boolean | string
  primaryVariant?: boolean | string
  dark?: boolean | string
}

export type TypographyProps = {
  light?: boolean | string
  centered?: boolean | string
  uppercase?: boolean | string
  lowercase?: boolean | string
  underline?: boolean | string
  color?: string
  fontSize?: number
  accent?: boolean
  as?: any
  bold?: boolean
  clickable?: boolean
  onAccent?: boolean
  white?: boolean
  lightHover?: boolean
} & ThemeColorProps

export const bodyFontBold = css`
  font-family: 'Aeonik-Bold';
`

export const bodyFontRegular = css`
  font-family: 'Aeonik-Regular';
`

export const typographyBase = css`
  color: var(--text);
  ${bodyFontRegular}
`

const typographyProps = css<TypographyProps>`
  ${({ uppercase }) => uppercase && 'text-transform: uppercase;'}
  ${({ clickable }) => clickable && 'cursor: pointer;'}
  ${({ accent }) => accent && 'color: var(--accent);'}
  ${({ light }) => light && 'opacity: 0.7;'}
  ${({ lightHover }) => lightHover && '&:hover {opacity:1};'}
  ${({ onAccent }) => onAccent && 'color: var(--background);'}
  ${({ color }) => color && `color: ${color};`}
  ${({ centered }) => centered && 'text-align: center;'}
  ${({ white }) => white && 'color: #FFF;'}
`

export type TypographyComponent<T = {}> = FunctionComponent<TypographyProps & T>

export const title1Styles = css`
  font-family: 'Marvin Visions Variable';
  font-size: 14rem;
  line-height: 0.8;
  letter-spacing: 4px;
  ${breakpoints.mobile} {
    font-size: 7rem;
  }
`

export const Title1 = styled.h1<TypographyProps>`
  ${typographyBase}
  ${title1Styles}
  ${typographyProps}
`

export const Title2 = styled.h2<TypographyProps>`
  ${typographyBase}
  font-family: 'Marvin Visions Variable';
  font-size: 8rem;
  line-height: 0.9;
  letter-spacing: 2px;

  ${breakpoints.tablet} {
    font-size: 5rem;
  }
  ${typographyProps}
`

export const Title3 = styled.h2<TypographyProps>`
  ${typographyBase}
  font-family: 'Marvin Visions Variable';
  font-size: 4.8rem;
  ${typographyProps}
`

export const Title4 = styled.h2<TypographyProps>`
  ${typographyBase}
  font-family: 'Marvin Visions Variable';
  font-size: 3.6rem;
  ${typographyProps}
`

export const Headline1 = styled.h1<TypographyProps>`
  ${typographyBase}
  ${bodyFontBold}
  font-size: 3.6rem;
  ${breakpoints.mobile} {
    font-size: 2.8rem;
  }
  ${typographyProps}
`

export const Headline2 = styled.h2<TypographyProps>`
  ${typographyBase}
  ${bodyFontBold}
  font-size: 3.2rem;
  line-height: 4.8rem;
  ${typographyProps}
`

export const Headline3 = styled.h3<TypographyProps>`
  ${typographyBase}
  ${bodyFontBold}
  font-size: 2.6rem;
  letter-spacing: 0.1rem;
  ${typographyProps}
`

export const Headline4 = styled.h4<TypographyProps>`
  ${typographyBase}
  ${bodyFontBold}
  font-size: 2.2rem;
  ${typographyProps}
`
export const Headline5 = styled.h5<TypographyProps>`
  ${typographyBase}
  ${bodyFontBold}
  font-size: 2rem;
  ${typographyProps}
`

export const Headline6 = styled.h6<TypographyProps>`
  ${typographyBase}
  ${bodyFontBold}
  font-size: 1.6rem;
  ${typographyProps}
`

export const body1Styles = css<{ bold?: boolean }>`
  ${bodyFontRegular}
  font-size: 2.4rem;
  line-height: 3.84rem;
  ${({ bold }) => bold && bodyFontBold}
`

export const Body1 = styled.p<TypographyProps>`
  ${typographyBase}
  ${body1Styles}
  ${typographyProps}
`

export const body2Styles = css<{ bold?: boolean }>`
  ${bodyFontRegular}
  font-size: 2rem;
  line-height: 1.3;
  ${({ bold }) => bold && bodyFontBold}
`

export const Body2 = styled.p<TypographyProps>`
  ${typographyBase}
  ${body2Styles}
  ${typographyProps}
`

export const Body3 = styled.p<TypographyProps>`
  ${typographyBase}
  font-size: 1.8rem;
  line-height: 1.3;
  ${bodyFontRegular}
  ${typographyProps}
`
export const Body4 = styled.p<TypographyProps>`
  ${typographyBase}
  font-size: 1.4rem;
  line-height: 1.3;
  letter-spacing: 0.12rem;
  ${bodyFontRegular}
  ${typographyProps}
`

export const anchorStyles = css`
  cursor: pointer;
  ${bodyFontRegular}

  &:hover {
    color: var(--accent);
  }
`

export const CopyRight = styled.p<TypographyProps>`
  ${typographyBase}
  ${bodyFontRegular}
  font-size: 1.2rem;
  ${typographyProps}
`

export const Anchor = styled.a<TypographyProps>`
  ${typographyBase}
  ${body2Styles}
  ${breakpoints.mobile} {
    ${body1Styles}
  }
  ${typographyProps}
  ${anchorStyles}
`

export const Outlined = styled.span`
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
`

export const Accent = styled.span.attrs(() => ({
  className: 'accent bold',
}))``
