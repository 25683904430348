import styled, { css, keyframes } from 'styled-components'
import { breakpoints } from '../../theme/mediaBreakpoints'

export const Container = styled.header<{
  showBackground: boolean
  transparent: boolean
}>`
  padding: 2rem 2rem;
  height: 80px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: background 0.25s;
  background: var(--background);

  ${breakpoints.mobile} {
    height: 80px;
    padding: 3rem;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & > * {
    margin-right: 10px;
  }

  svg {
    height: 40px;
  }

  ${breakpoints.mobile} {
    img {
      display: none;
    }
  }
`

export const NavContainer = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-left: 5rem;
  }
`

const fadeIn = keyframes`
  from {
    background: rgba(0, 0, 0, 0);
  }

  to {
    transform: rgba(255, 255, 255, 1);
  }
`

const overlayAnimateIn = css`
  animation: ${fadeIn} 2s forwards;
`

const overlayAnimateOut = css`
  animation: ${fadeIn} 2s reverse;
`

export const Overlay = styled.div`
  height: 100vh;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 1);
`

export const DrawerBackdrop = styled.div`
  background: var(--background);
  padding: 9rem 6rem 6rem 6rem;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0rem;

  height: 100vh;
  min-height: 800px;

  ${breakpoints.mobile} {
    height: 100%;
    padding: 9rem 3rem;
    justify-content: flex-end;
    display: block;

    & * {
      text-align: right;
    }
  }

  & * {
    padding-bottom: 2rem;
  }
`

export const InfoContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto;
  padding-bottom: 6rem;
  grid-column-gap: 6rem;

  & > *:first-child {
    grid-column: 1 / -1;
  }

  ${breakpoints.tablet} {
    display: none;
  }

  ${breakpoints.ultrawide} {
    grid-template-columns: 1fr 1fr;
  }
`

export const HiddenTablet = styled.div`
  ${breakpoints.tablet} {
    display: none;
  }
`

export const DrawerMenu = styled.div`
  margin: 0 auto;
  padding-top: 2rem;

  & > div {
    width: fit-content;
    margin: 0 auto;
  }

  ${breakpoints.tablet} {
    & > div {
      width: auto;
      margin: unset;
    }
  }

  & h1 {
    margin-bottom: 2rem;
  }

  ${breakpoints.mobile} {
    margin: initial;

    & h1 {
      margin-top: 0;
    }
  }
`

export const DrawerIcon = styled.div`
  display: grid;
  grid-gap: 8px;
  cursor: pointer;
  padding: 2rem;
  margin-right: -2rem;
`

const crossOneAnim = css`
  transform: rotate(-45deg) translate(-15px, 8px);
`

const crossTwoAnim = css`
  width: 0px;
`

const crossThreeAnim = css`
  transform: rotate(45deg);
`

export const DrawerLine = styled.div<{
  crossOne?: boolean
  crossTwo?: boolean
  crossThree?: boolean
}>`
  height: 1px;
  width: 62px;
  background: var(--foreground);

  transition: 400ms;

  ${({ crossOne }) => crossOne && crossOneAnim}
  ${({ crossTwo }) => crossTwo && crossTwoAnim}
  ${({ crossThree }) => crossThree && crossThreeAnim}
`
