export const breakpointValues = {
  mobile: 780,
  tablet: 1080,
  ultrawide: 2561,
  md: 992,
}

export const breakpoints = {
  notMobile: `@media screen and (min-width: ${breakpointValues.mobile}px)`,
  mobile: `@media screen and (max-width: ${breakpointValues.mobile}px)`,
  tablet: `@media screen and (max-width: ${breakpointValues.tablet}px)`,
  ultrawide: `@media screen and (min-width: ${breakpointValues.ultrawide}px)`,
  md: `@media screen and (max-width: ${breakpointValues.md}px)`,
}

export const mediaQueries = {
  mobile: `(max-width: ${breakpointValues.mobile}px)`,
  tablet: `(max-width: ${breakpointValues.tablet}px)`,
}
