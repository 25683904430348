import React, { ImgHTMLAttributes, useEffect, useState } from 'react'
import * as S from './styled'

export type ImageProps = {
  src: string
  preview?: string
  rounded?: boolean
  useImageElement?: boolean
}

type Props = ImageProps & ImgHTMLAttributes<HTMLImageElement>

const Image: React.FC<Props> = ({
  preview,
  src,
  rounded = true,
  useImageElement = true,
  ...imgProps
}) => {
  const [currentSrc, setCurrentSrc] = useState(preview ?? src)

  useEffect(() => {
    if (!preview) return () => {}

    const img = new window.Image()
    img.src = src
    img.onload = () => setCurrentSrc(src)

    return () => {
      img.onload = null
      img.remove()
    }
  }, [src, preview])

  const blur = currentSrc === preview

  if (useImageElement) {
    return (
      <S.Container rounded={!!rounded}>
        <S.Image {...imgProps} blur={blur} src={currentSrc} />
      </S.Container>
    )
  }

  return <S.BackgroundImage {...imgProps} blur={blur} src={currentSrc} />
}

export default Image
