import React from 'react'

function ChatBubble() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" fill="none" viewBox="0 0 23 22">
      <path
        fill="#fff"
        d="M11.5 0C5.15 0 0 4.297 0 9.604c0 5.308 5.15 9.605 11.5 9.605 1 0 2.05-.101 3.05-.354l5.75 2.325c.05 0 .1.05.2.05s.2-.05.3-.1c.15-.101.2-.304.2-.455l-.55-5.055C22.1 13.952 23 11.778 23 9.604 23 4.297 17.85 0 11.5 0z"
      ></path>
    </svg>
  )
}

export default ChatBubble
